@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
iframe {
  pointer-events: none;
}
.css-q581sn-MuiPaginationItem-root {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.PhoneInput {
  border: 1px solid rgb(208, 208, 208);
  border-radius: 8px;
  height: 47px;
}

input[type='tel'] {
  background: transparent !important;
  border-color: transparent !important;
  height: 44px !important;
  font-size: 16px;
}

input[type='tel']:focus-visible {
  outline: none !important;
}

input[type='tel']:disabled {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.38);
}

.PhoneInputCountryIcon {
  width: 36px !important;
  height: 20px !important;
}

.PhoneInputCountryIconImg {
  border-radius: 7px !important;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background-color: transparent !important;
}

