/* .draw { */
  /* width: 60vw;
    height: 60vh;
    border: solid 2px #4285F4;

    position: relative;
    background: pink;
    animation: changeBorders 5s linear;
    border-radius: 20px; */
  /* display: flex;
  flex-direction: row;
  margin-top: 1;
  color: black;

  justify-content: space-between;
  width: 960px;
  min-height: 165px;
  
  box-sizing: border-box;

  border-radius: 16px;
  background: #f5f5f5; */
/* } */
.draw3 {
  padding: 20px;
  width: 100%;
  /* height: 100%; */
  border-radius: 16px;
  border: solid 2px white;
  /* background: #f5f5f5; */
  position: relative;
}

.draw2 {
  padding: 20px;
  width: 100%;
  height: 100%;
  border: solid 2px #4285f4;
  border-radius: 16px;
  /* background: #f5f5f5; */
  position: relative;

  animation: changeBorders 5s forwards;
}

@keyframes changeBorders {
  0% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-radius: 8px;
  }
  25% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    /* border-radius: 8px; */
  }
  /* 25.02% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    border-radius: 8px;
  } */
  50% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    /* border-radius: 8px; */
  }
  /* 50.02% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    border-right: solid 2px #4285f4;
    border-radius: 8px;
  } */
  75% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    border-right: solid 2px #4285f4;
    /* border-radius: 8px; */
  }
  /* 75.02% {
    border: solid 2px #4285f4;
    border-radius: 8px;
  } */
}

.draw2::after {
  background-color: white;

  position: absolute;
  bottom: 0;
  left: -2px;
  z-index: 1;
  animation: movedown 5s linear;
  animation-fill-mode: forwards;
  content: '';
  display: inline-block;
  border-radius: 8px;
}

@keyframes movedown {
  0% {
    height: calc(100% + 50px);
    width: 2px;
    /* bottom: -2px; */
    left: -2px;
  }
  25% {
    height: 2px;
    width: 2px;
    bottom: -2px;
    /* left: -2px; */
  }
  25.01% {
    height: 2px;
    width: calc(100% + 50px);
    /* bottom: -2px; */
    left: -2px;
  }
  50% {
    height: 2px;
    width: 0%;
    left: 100%;
    /* bottom: -2px; */
  }
  50.01% {
    height: calc(100% + 50px);
    width: 2px;
    left: 100%;
    bottom: -2px;
  }
  75% {
    height: 0;
    width: 2px;
    left: 100%;
    bottom: 100%;
  }
  75.01% {
    height: 2px;
    width: calc(100% + 50px);
    left: 0%;
    bottom: 100%;
  }
  99.01% {
    height: 2px;
    width: 0;
    left: 0;
    bottom: 100%;
  }
}

@media (max-width: 1536px) {
  .draw2 {
    padding: 10px;
  }
  .draw3 {
    padding: 10px;
  }
}
