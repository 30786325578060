.MuiSelect-select.MuiSelect-select {
  padding: 12px 12px;
}
.MuiSelect-icon {
  right: 12px;
}
/* .MuiInput-underline:before {
  border-bottom: none ;
}
.MuiInput-underline:after {
  border-bottom: none;
}
.MuiInput-underline:hover {
  border-bottom: none;
}
.MuiInput-underline:hover {
  border: none;
}
*/
