@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
iframe {
  pointer-events: none;
}
.css-q581sn-MuiPaginationItem-root {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.PhoneInput {
  border: 1px solid rgb(208, 208, 208);
  border-radius: 8px;
  height: 47px;
}

input[type='tel'] {
  background: transparent !important;
  border-color: transparent !important;
  height: 44px !important;
  font-size: 16px;
}

input[type='tel']:focus-visible {
  outline: none !important;
}

input[type='tel']:disabled {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.38);
}

.PhoneInputCountryIcon {
  width: 36px !important;
  height: 20px !important;
}

.PhoneInputCountryIconImg {
  border-radius: 7px !important;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background-color: transparent !important;
}


/* .draw { */
  /* width: 60vw;
    height: 60vh;
    border: solid 2px #4285F4;

    position: relative;
    background: pink;
    animation: changeBorders 5s linear;
    border-radius: 20px; */
  /* display: flex;
  flex-direction: row;
  margin-top: 1;
  color: black;

  justify-content: space-between;
  width: 960px;
  min-height: 165px;
  
  box-sizing: border-box;

  border-radius: 16px;
  background: #f5f5f5; */
/* } */
.draw3 {
  padding: 20px;
  width: 100%;
  /* height: 100%; */
  border-radius: 16px;
  border: solid 2px white;
  /* background: #f5f5f5; */
  position: relative;
}

.draw2 {
  padding: 20px;
  width: 100%;
  height: 100%;
  border: solid 2px #4285f4;
  border-radius: 16px;
  /* background: #f5f5f5; */
  position: relative;

  animation: changeBorders 5s forwards;
}

@keyframes changeBorders {
  0% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-radius: 8px;
  }
  25% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    /* border-radius: 8px; */
  }
  /* 25.02% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    border-radius: 8px;
  } */
  50% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    /* border-radius: 8px; */
  }
  /* 50.02% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    border-right: solid 2px #4285f4;
    border-radius: 8px;
  } */
  75% {
    border: solid 2px white;
    border-left: solid 2px #4285f4;
    border-bottom: solid 2px #4285f4;
    border-right: solid 2px #4285f4;
    /* border-radius: 8px; */
  }
  /* 75.02% {
    border: solid 2px #4285f4;
    border-radius: 8px;
  } */
}

.draw2::after {
  background-color: white;

  position: absolute;
  bottom: 0;
  left: -2px;
  z-index: 1;
  animation: movedown 5s linear;
  animation-fill-mode: forwards;
  content: '';
  display: inline-block;
  border-radius: 8px;
}

@keyframes movedown {
  0% {
    height: calc(100% + 50px);
    width: 2px;
    /* bottom: -2px; */
    left: -2px;
  }
  25% {
    height: 2px;
    width: 2px;
    bottom: -2px;
    /* left: -2px; */
  }
  25.01% {
    height: 2px;
    width: calc(100% + 50px);
    /* bottom: -2px; */
    left: -2px;
  }
  50% {
    height: 2px;
    width: 0%;
    left: 100%;
    /* bottom: -2px; */
  }
  50.01% {
    height: calc(100% + 50px);
    width: 2px;
    left: 100%;
    bottom: -2px;
  }
  75% {
    height: 0;
    width: 2px;
    left: 100%;
    bottom: 100%;
  }
  75.01% {
    height: 2px;
    width: calc(100% + 50px);
    left: 0%;
    bottom: 100%;
  }
  99.01% {
    height: 2px;
    width: 0;
    left: 0;
    bottom: 100%;
  }
}

@media (max-width: 1536px) {
  .draw2 {
    padding: 10px;
  }
  .draw3 {
    padding: 10px;
  }
}

.MuiSelect-select.MuiSelect-select {
  padding: 12px 12px;
}
.MuiSelect-icon {
  right: 12px;
}
/* .MuiInput-underline:before {
  border-bottom: none ;
}
.MuiInput-underline:after {
  border-bottom: none;
}
.MuiInput-underline:hover {
  border-bottom: none;
}
.MuiInput-underline:hover {
  border: none;
}
*/

